import React, { useEffect, useState } from 'react';
import './dataDownloadBody.sass';
import Modal from './ModaldataDownload'; // Import the Modal component
import { useSelector, useDispatch } from "react-redux";
import { downloadMonthlyData, downloadAllData} from "redux/slices/dataSlice";

export default function DataDownloadBody(){
    const [showModal, setShowModal] = useState(false);
    const dispatch = useDispatch();
    const { monthListSinceJoined, trapLst, probes } = useSelector((state) => state.auth);
    const [selectedMonth, setSelectedMonth] = useState(monthListSinceJoined[0]);
    const [activeTab, setActiveTab] = useState('tab1'); // State to manage active tab

    const renderDropdownOptions = (list) => {
        return list.map((item, index) => (
            <option key={index} value={item}>
                {item}
            </option>
        ));
    };
    const handleSelectChange = (event) => {
        setSelectedMonth(event.target.value);
        console.log(event.target.value);
    };
    const handleSubmit = () => {
        let payload = {
            probes : probes.probes,
            timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            month: selectedMonth,
        }
        // Show the modal
        setShowModal(true);
        dispatch(downloadMonthlyData(payload));
    };

    const handleSubmitAllData = () => {
        let payload = {
            timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        }
        // Show the modal
        setShowModal(true);
        dispatch(downloadAllData(payload));
    };

    const handleTabClick = (tab) => {
        setActiveTab(tab);
      };
    return (
      <div className="data-box">
      <div className="data-container">
        <div className="data-title">
          <h2>Data Download</h2>
          <div className="data-division-container">
            <div className="data-days-container">
              <div className="tab-bar">
                <button onClick={() => handleTabClick('tab1')} className={activeTab === 'tab1' ? 'active' : ''}>Monthly Report</button>
                <button onClick={() => handleTabClick('tab2')} className={activeTab === 'tab2' ? 'active' : ''}>Download All Data</button>
              </div>
            </div>
            <div className="right-container">
              {activeTab === 'tab1' && (
                <div className="tab-content">
                  <div className="data-list-title-container">
                    <div className="data-list-title">
                      Choose a month to receive a report
                    </div>
                    <div className="row-container">
                      <div className="month-list-months">
                        <select className="fixed-length-dropdown" value={selectedMonth} onChange={handleSelectChange}>
                          {renderDropdownOptions(monthListSinceJoined)}
                        </select>
                      </div>
                      <button className="data-button" onClick={handleSubmit}>Download</button>
                    </div>
                  </div>
                </div>
              )}
              {activeTab === 'tab2' && (
                <div className="tab-content">
                  <div className="data-list-title-container">
                    <div className="data-list-title">
                      Download all data (Including current and past sessions)
                    </div>
                    <div className="row-container">
                      <button className="data-button" onClick={handleSubmitAllData}>Download</button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <Modal show={showModal} onClose={() => setShowModal(false)}>
        Your download has started.
      </Modal>
    </div>
    );
}