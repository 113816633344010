import React, { useEffect, useState } from 'react';
import './AccountThreshold.sass';

import { useSelector, useDispatch } from "react-redux";
import { fetchUserLst, changeInsectCountThreshold } from "redux/slices/authSlice";

import insect_dark_red from "assets/insect_dark_red.png";
import insect_green from "assets/insect_green.png";
import insect_red from "assets/insect_red.png";
import insect_yellow from "assets/insect_yellow.png";

export default function AccountThreshold(){

    const dispatch = useDispatch();
    const { yellow, lightRed, darkRed } = useSelector((state) => state.auth);

  useEffect(() => {
    dispatch(fetchUserLst());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [greenLow, setGreenLow] = useState(0);
  const [greenHigh, setGreenHigh] = useState(yellow);
  const [yellowLow, setYellowLow] = useState(yellow);
  const [yellowHigh, setYellowHigh] = useState(lightRed);
  const [lightRedLow, setLightRedLow] = useState(lightRed);
  const [lightRedHigh, setLightRedHigh] = useState(darkRed);
  const [darkRedLow, setDarkRedLow] = useState(darkRed);

  const [decDisableGreen, setDecDisableGreen] = useState(false);
  const [decDisableYellow, setDecDisableYellow] = useState(false);
  const [decDisableLightRed, setDecDisableLightRed] = useState(false);
  const [incDisableGreen, setIncDisableGreen] = useState(false);
  const [incDisableYellow, setIncDisableYellow] = useState(false);

  const [loading, setLoading] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(true);

  const setDefaultValues = async () => {
    setGreenLow(0);
    setGreenHigh(1);
    setYellowLow(1);
    setYellowHigh(5);
    setLightRedLow(5);
    setLightRedHigh(7);
    setDarkRedLow(7);
    if (
      yellowLow !== yellow ||
      lightRedLow !== lightRed ||
      darkRedLow !== darkRed
    ) {
      setButtonDisabled(false);
    } else {
      setButtonDisabled(true);
    }
  };

  const changeThresholds = async () => {
    setLoading(true);

    let msg = {
      yellow: greenHigh,
      lightRed: yellowHigh,
      darkRed: lightRedHigh,
    };

    await dispatch(changeInsectCountThreshold(msg));
    await dispatch(fetchUserLst());

    setTimeout(() => {
      setLoading(false);
      setButtonDisabled(true);
    }, 1000);
  };

  useEffect(() => {
    greenHigh === 0 ? setDecDisableGreen(true) : setDecDisableGreen(false);
    greenHigh + 1 === yellowHigh
      ? setIncDisableGreen(true)
      : setIncDisableGreen(false);
  }, [greenHigh, yellowHigh]);

  useEffect(() => {
    yellowLow + 1 === lightRedLow
      ? setDecDisableYellow(true)
      : setDecDisableYellow(false);
    yellowHigh + 1 === lightRedHigh
      ? setIncDisableYellow(true)
      : setIncDisableYellow(false);
  }, [yellowLow, yellowHigh, lightRedLow, lightRedHigh]);

  useEffect(() => {
    lightRedLow + 1 === darkRedLow
      ? setDecDisableLightRed(true)
      : setDecDisableLightRed(false);
  }, [lightRedLow, darkRedLow]);

  useEffect(() => {
    if (
      yellowLow !== yellow ||
      lightRedLow !== lightRed ||
      darkRedLow !== darkRed
    ) {
      setButtonDisabled(false);
    } else {
      setButtonDisabled(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [yellowLow, lightRedLow, darkRedLow]);

    return (
        <div className="account-box">
            <div className="account-container">
                <div className="account-title">
                    <h2>Insect Count Threshold</h2>
                    <div className="row-threshold-container">
                        <div>
                        <img className="icon-img" src={insect_green} alt="Green Insect" />
                        </div>
                        <div className="text-threshold-container">
                            Range:{" "}
                            {greenHigh === 0 ? (
                                <div className="num-container">
                                0-
                                
                            </div>
                                ) : (
                                    <div className="num-container">
                                        0-
                                        <div style={{ color: "#1870d5" }}>{greenHigh}</div>
                                    </div>
                                )}
                            <button className={incDisableGreen ? "decDisableGreen" : "decGreen"} 
                                onClick={() => {
                                    setGreenHigh(greenHigh - 1);
                                    setYellowLow(yellowLow - 1);
                                }}
                                disabled={decDisableGreen}
                                >
                                -
                            </button>
                            <button className={incDisableGreen ? "incDisableGreen" : "incGreen"}
                                onClick={() => {
                                    setGreenHigh(greenHigh + 1);
                                    setYellowLow(yellowLow + 1);
                                }}
                                disabled={incDisableGreen}
                                >
                                +
                            </button>
                        </div>
                    </div>
                    <div className="row-threshold-container">
                        <div>
                            <img className="icon-img" src={insect_yellow} alt="Yellow Insect" />
                        </div>
                        <div className="text-threshold-container">
                            Range:{" "}
                            {yellowLow + 1 === yellowHigh ? (
                                <div className="num-container">
                                {yellowLow + 1}-
                            </div>
                                ) : (
                                    <div className="num-container">
                                        {yellowLow + 1}-
                                        <div style={{ color: "#1870d5" }}>{yellowHigh}</div>
                                    </div>
                                )}
                            <button className={decDisableYellow ? "decDisableGreen" : "decGreen"} 
                                onClick={() => {
                                    setYellowHigh(yellowHigh - 1);
                                    setLightRedLow(lightRedLow - 1);
                                }}
                                disabled={decDisableYellow}
                                >
                                -
                            </button>
                            <button className={incDisableYellow ? "incDisableGreen" : "incGreen"}
                                onClick={() => {
                                    setYellowHigh(yellowHigh + 1);
                                    setLightRedLow(lightRedLow + 1);
                                }}
                                disabled={incDisableYellow}
                                >
                                +
                            </button>
                        </div>
                    </div>
                    <div className="row-threshold-container">
                        <div>
                            <img className="icon-img" src={insect_red} alt="Red Insect" />
                        </div>
                        <div className="text-threshold-container">
                            Range:{" "}
                            {lightRedLow + 1 === lightRedHigh ? (
                                 <div className="num-container">
                                 {lightRedLow + 1}-
                              </div>
                                ) : (
                                    <div className="num-container">
                                        {lightRedLow + 1}-
                                        <div style={{ color: "#1870d5" }}>{lightRedHigh}</div>
                                    </div>
                                )}
                            <button className={decDisableLightRed ? "decDisableGreen" : "decGreen"} 
                                onClick={() => {
                                    setLightRedHigh(lightRedHigh - 1);
                                    setDarkRedLow(darkRedLow - 1);
                                }}
                                disabled={decDisableLightRed}
                                >
                                -
                            </button>
                            <button className={decDisableLightRed ? "incDisableGreen" : "incGreen"}
                                onClick={() => {
                                    setLightRedHigh(lightRedHigh + 1);
                                    setDarkRedLow(darkRedLow + 1);
                                }}
                                disabled={incDisableGreen}
                                >
                                +
                            </button>
                        </div>
                    </div>
                    <div className="row-threshold-container">
                        <div>
                            <img className="icon-img" src={insect_dark_red} alt="Dark Red Insect" />
                        </div>
                        <div className="text-threshold-container">
                            Range:{" "} {darkRedLow + 1}-∞
                        </div>
                    </div>
                    <div className="row-threshold-container">
                        <div className="account-btn-threshold">
                            <button className="account-button-threshold" onClick={() => setDefaultValues()}>Default</button>
                        </div>
                        <div className="account-btn-threshold">
                            <button className="account-button-threshold" onClick={() => changeThresholds()}>Save</button>
                        </div>
                    </div>
                    <div >
              </div>
                </div>
                
            </div>
        </div>
    );
}