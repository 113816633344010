import React from 'react';
import './account.sass';

import AccountBox from 'components/box/account-box/AccountBox';
import AccountFeatures from 'components/box/account-box/AccountFeatures';
import AccountThreshold from 'components/box/account-box/AccountThreshold/AccountThreshold';
import AccountEmail from 'components/box/account-box/AccountEmail/AccountEmail';

export default function Account(){
    return (
        <div className="account">
            <div className="account-body">
                <div className="account-title-container">
                    <h1>Account</h1>
                </div>
                <div>
                    <AccountBox />
                </div>
                <div className="account-title-container">
                    <h1>Account Features</h1>
                </div>
                <div>
                    <AccountFeatures />
                </div>
                <div>
                    <AccountThreshold />
                </div>
                <div>
                    <AccountEmail />
                </div>
            </div>
        </div>
    );
}

